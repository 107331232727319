import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Introduction',
    text: 'Owano & Associates Advocates is a globally recognized law firm headquartered in Nairobi, Kenya, with extensive collaborations across Africa, Europe, and the UAE. Our multi-disciplinary approach ensures efficient solutions for clients, backed by international legal expertise to deliver optimal service and adherence to best practices.',
  },
  {
    title: 'Vision',
    text: 'To provide superior, efficient and responsive service by recruiting, training and retaining talented Advocates of the High Court and team members dedicated to excellence in client services, while maintaining a collaborative, diverse and inclusive environment, which provides opportunities to drive business growth and productivity.',
  },
  {
    title: 'Mission',
    text: 'Our Mission is to provide our clients with a network of innovative legal solutions, excellent legal representation and a dedication to quality customer service.',
  },
  {
    title: 'Guiding Principles and Values',
    text: 'In our firm, integrity guides every action. We innovate to better serve clients. Collaboration fosters a supportive culture. Diversity fuels creativity and equitable outcomes. Excellence is our standard, ensuring total trust from clients.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">How We Practice Our Law</h1>
      <p>
        We can honestly say that our firm’s success and fine reputation is largely due to the much-appreciated efforts of our loyal and satisfied clients, who have proudly recommended our firm to others. Here are some of the services that we specialise in.
      </p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
