import React from 'react';
import { Link } from 'react-router-dom';

import Feature from '../../components/feature/Feature';
import './whatGPT3.css';
import Services from '../../api/service';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="We are here to protect your rights and serve your needs with experience" text="We are a firm of highly dynamic, creative and talented people, ready to go that extra mile to create premium experience for our clientele. It is our team that makes Owano & Associates a great place to work, and the reason why our clients choose to work with us on their most complex legal challenges. This is the foundation on which our successful and innovative reputation is based." />
      <Feature title="" text="We have created Owano & Associates to reinvent legal practice and industry standards, developing first-class solutions for our clients, partners, employees, and shareholders. Today I am proud to say that we have achieved this challenging goal in so many differentiating and effective ways." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Our Areas of Practice</h1>
      <p>Explore more</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      {Services.map((service) => (
        <Link to={`/practice/${service.Id}`}>
          <Feature title={service.sTitle} text="" />
        </Link>
      ))}
    </div>
  </div>
);

export default WhatGPT3;
