import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Article from '../../components/article/Article';
// import { blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => {
  const [blogs, setBlogs] = useState();
  const getAllPosts = async (searchKeyword = '', page = 1, limit = 10) => {
    try {
      const { data, headers } = await axios.get(
        `https://blog-be-gxka.onrender.com/api/posts?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`,
      );
      return { data, headers };
    } catch (error) {
      if (error.response && error.response.data.message) throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    getAllPosts().then((res) => {
      console.log('data: ', res.data);
      setBlogs(res.data);
      console.log(blogs);
    });
  }, []);
  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupB">
          {blogs?.map((blog) => (
            <Article imgUrl={blog.photo} date={blog.createdAt} text={blog.title} slug={blog.slug} />
          ))};
          {/* <Article imgUrl={blog02} date="Sep 26, 2021" text="Landlord or Tenant? Know Your Rights: A Guide to Kenyan Property Law" />
          <Article imgUrl={blog03} date="Sep 26, 2021" text="Employer & Employee Rights in Kenya: Myth vs. Reality" />
          <Article imgUrl={blog04} date="Sep 26, 2021" text="Navigating the Kenyan Family Law Labyrinth: A Compass for Marriage, Divorce & Custody" />
          <Article imgUrl={blog05} date="Sep 26, 2021" text="Safeguarding Your Innovations: An Entrepreneur's Guide to Kenyan IP Law" /> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
