import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import PracticePage from '../pages/PracticePage/practice';

const AllRoute = () => (
  <div>
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/practice/:id" component={PracticePage} />
      </Switch>
    </Router>
  </div>
);

export default AllRoute;
