import React, { Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import Services from '../../api/service';

import '../../App.css';

const PracticePage = () => {
  const { id } = useParams();
  const serviceDetails = Services.find((item) => item.Id === id);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      <div className="gpt3__features section__padding" id="features">
        <div className="gpt3__features-heading">
          <h1 className="gradient__text">{serviceDetails.sTitle}</h1>
          <p>{serviceDetails.description}</p>
        </div>
        <div className="gpt3__features-container">
          <div className="gpt3__features-container__feature-title">
            <div />
            <h1>Related Services</h1>
          </div>
          <div className="row">
            {Services.slice(0, 8).map((service) => (
              <div className="gpt3__features-container__feature">
                <div className="gpt3__footer-links_div">
                  <p><Link onClick={ClickHandler} to={`/practice/${service.Id}`}>{service.sTitle}</Link></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticePage;
