import React from 'react';
// import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Protecting your rights, Serving your needs.</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>Get Started</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        {/* <img src={gpt3Logo} alt="gpt3_logo" /> */}
        <p>Western Heights, Westlands, Nairobi <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Our Services</h4>
        <p>Laws of Contracts</p>
        <p>Conveyancing & Real Estate</p>
        <p>Employment, Labour, Pension & Retirement Benefit Schemes</p>
        <p>Corporate & Commercial Laws</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Linkedin</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Western Heights, Westlands, Nairobi</p>
        <p>+254 715 861 024</p>
        <p>inquiry@owanoandassociates.co.ke</p>
        <div className="gradient__text">
          <a
            href={`https://wa.me/${+254715861024}`}
            target="_blank"
            rel="noopener noreferrer"
            color="#ffffff"
          >
            Contact Us on WhatsApp
          </a>
        </div>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2024 Owano & Associates. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
