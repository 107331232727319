import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Visit us to Get Started</h4>
      <h1 className="gradient__text">Commitment to Protecting Your Rights and Serving Your Needs</h1>
      <p>We back our commitment to protecting your rights, serving your needs with extensive expertise, positioning us as industry leaders in legal solutions. Our success stems from innovation and strategic growth, allowing us to excel in diverse legal domains. Explore our website to discover our services and reach out to explore how we can assist you.</p>
      <h4>We eagerly anticipate the opportunity to collaborate with you.</h4>
    </div>
  </div>
);

export default Possibility;
