import React from 'react';
import './article.css';

const Article = ({ date, text }) => (
  <div className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      {/* <img src={imgUrl} alt="blog_image" /> */}
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <p>{date}</p>
        <h3>{text}</h3>
      </div>
      <a href="https://owano-blog-fe.onrender.com">Read All Article</a>
    </div>
  </div>
);

export default Article;
